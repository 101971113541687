<template>
  <!-- At the moment no need-->
  <div>
    <v-card class="mx-auto" max-width="600" outlined style>
      <form
        ref="myAccount"
        action="/?f=administration&f2=userSelfService&f3=myAccount"
        method="post"
      >
        <v-row>
          <v-col :cols="9">
            <fieldsSingleRows
              field="n_id"
              :templateContent="result"
              :valueLine="result.valueLine"
            ></fieldsSingleRows>
            <fieldsSingleRows
              field="c_firstname"
              :templateContent="result"
              :valueLine="result.valueLine"
            ></fieldsSingleRows>
            <fieldsSingleRows
              field="c_lastname"
              :templateContent="result"
              :valueLine="result.valueLine"
            ></fieldsSingleRows>
          </v-col>
        </v-row>

        <v-row style="margin-top: -24px">
          <v-col :cols="2">{{language.componentLabel.labelEmail}}:</v-col>
          <fieldsTds
            :field="'c_email'"
            :fieldAttributes="result.fields.c_email"
            :templateContent="result"
            :value="result.valueLine.c_email"
          ></fieldsTds>
        </v-row>
        <v-row style="margin-bottom: 15px">
          <v-col :cols="2">{{language.componentLabel.labelRole}}:</v-col>
          <fieldsTds
            field="n_type"
            :fieldAttributes="result.fields.n_type"
            :templateContent="result"
            :value="result.valueLine.n_type"
          ></fieldsTds>
        </v-row>
      </form>
    </v-card>
  </div>
</template>
<script>
import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
import fieldsTds from "@/commonComponents/fieldsTds.vue";

export default {
  components: {
    fieldsSingleRows,
    fieldsTds,
  },
  props: ["result"],
  data: function () {
    return {};
  },
  methods: {
    /*      submitForm(functionName) {
        this.ajaxSubmit(functionName, this.$refs.myAccount, "", this.result);
        this.$notify({
          group: "foo",
          text: "Successfully Updated!!",
          duration: 1000,
          type: "success",
          speed: 600,
        });
      },*/
  },
};
</script>



